export const EyeHidden = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5625 15.6875C11.0474 15.7849 10.5242 15.8337 9.99999 15.8333C6.26832 15.8333 3.10999 13.3808 2.04749 10C2.33354 9.09008 2.77415 8.23622 3.34999 7.47583M8.23165 8.2325C8.70053 7.76362 9.33647 7.5002 9.99957 7.5002C10.6627 7.5002 11.2986 7.76362 11.7675 8.2325C12.2364 8.70138 12.4998 9.33732 12.4998 10.0004C12.4998 10.6635 12.2364 11.2995 11.7675 11.7683M8.23165 8.2325L11.7675 11.7683M8.23165 8.2325L11.7667 11.7667M11.7675 11.7683L14.51 14.51M8.23332 8.23333L5.49165 5.49167M5.49165 5.49167L2.49999 2.5M5.49165 5.49167C6.83528 4.62464 8.4009 4.1645 9.99999 4.16667C13.7317 4.16667 16.89 6.61917 17.9525 10C17.3659 11.8587 16.1478 13.4538 14.5092 14.5092M5.49165 5.49167L14.5092 14.5092M14.5092 14.5092L17.5 17.5"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
