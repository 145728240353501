export const LogoSmall = () => (
  <svg fill="none" height="32" viewBox="0 0 26 32" width="26" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M16.3455 0.407776C13.6451 2.1558 11.2503 4.22522 11.2503 7.25488C11.2503 10.8803 16.6277 12.6009 19.2066 15.0191C20.1463 15.8839 20.9007 16.9303 21.4241 18.0951C21.9476 19.2599 22.2292 20.5188 22.2519 21.7957C23.0336 20.5636 23.552 19.1831 23.7746 17.7411C23.9971 16.299 23.9191 14.8265 23.5453 13.4161C22.9834 11.2686 21.7519 9.35595 20.0295 7.95563C18.3071 6.55531 16.1834 5.74013 13.9664 5.62835C14.5977 4.95616 15.2776 4.3313 16.0006 3.75882C17.8068 4.1474 19.5062 4.92652 20.9794 6.04149C22.4526 7.15647 23.6639 8.58031 24.5285 10.2131C25.3931 11.8459 25.8899 13.6481 25.9841 15.4933C26.0783 17.3384 25.7676 19.1818 25.0739 20.8942C24.482 22.4155 23.5858 23.7998 22.4401 24.9625C21.263 26.1816 19.851 27.1496 18.2895 27.8079C20.7234 25.0174 20.8253 20.1417 18.3992 17.2884C15.3029 13.6513 9.10645 13.3142 9.10645 7.25488C9.10645 2.91616 13.3041 1.13677 16.3455 0.407776Z"
      fill="#004876"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.3091 31.8724C13.0134 30.1243 15.4042 28.0549 15.4042 25.0253C15.4042 21.3999 10.0229 19.6793 7.44402 17.261C6.5056 16.3955 5.75229 15.3488 5.22956 14.1841C4.70683 13.0194 4.42552 11.7609 4.4026 10.4845C3.62294 11.7174 3.10662 13.0983 2.88607 14.5403C2.66551 15.9823 2.74549 17.4544 3.12098 18.864C3.68421 21.0116 4.91658 22.924 6.63952 24.3242C8.36246 25.7244 10.4865 26.5396 12.7038 26.6518C12.0715 27.323 11.3917 27.9478 10.6696 28.5213C8.38406 28.0427 6.27693 26.937 4.58438 25.3283C2.89183 23.7195 1.68074 21.6711 1.08684 19.4127C0.378362 16.7542 0.551653 13.9376 1.58067 11.3859C2.17397 9.8645 3.07152 8.48015 4.2184 7.31764C5.39548 6.09849 6.80743 5.13053 8.36898 4.4722C5.93507 7.26277 5.82925 12.1384 8.25924 14.9917C11.3555 18.6289 17.552 18.9659 17.552 25.0253C17.552 29.364 13.3544 31.1434 10.3091 31.8724Z"
      fill="#004876"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.38773 3.99002L8.88997 4.27613C8.88997 4.27613 6.53836 6.45529 6.93029 10.6803C7.0341 11.8331 7.41793 12.943 8.04839 13.9137C8.67886 14.8843 9.53688 15.6862 10.5479 16.2497C12.3116 17.2766 15.2236 18.9149 16.5758 20.6472C17.1389 21.3468 17.5531 22.154 17.793 23.0194C18.033 23.8848 18.0936 24.7901 17.9711 25.6797C17.8785 26.565 17.5703 27.4141 17.0736 28.1528L17.5556 27.8824C17.5556 27.8824 20.0719 24.7469 19.2684 20.9177C18.4649 17.0885 15.7527 15.6579 14.33 14.7917C13.2326 14.149 9.88548 12.4754 8.92132 10.4178C8.10218 8.67364 8.31774 6.25932 9.38773 3.99002Z"
      fill="#4B858E"
      fillRule="evenodd"
    />
  </svg>
);
