import { Box, BoxProps, ButtonProps } from '@chakra-ui/react';
import Head from 'next/head';

import { Nav } from '../navigation/Nav';

interface PageProps extends BoxProps {
  children: React.ReactNode;
  title: string;
  hideNavigation?: boolean;
  RightButton?: ButtonProps;
}

/** Renders a full page with navigation and title */
export const Page = ({ children, title, hideNavigation, RightButton, ...props }: PageProps) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      {!hideNavigation && <Nav RightButton={RightButton} title={title} />}

      <Box {...props}>{children}</Box>
    </>
  );
};
