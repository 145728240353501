import { Flex, FlexProps } from '@chakra-ui/react';

/** Gives defaults for padding and max width to wrap pages */
export const PageContainer = ({ children, ...props }: FlexProps) => {
  return (
    <Flex
      direction="column"
      maxWidth={['100%', '100%', '768px', '768px']}
      mx="auto"
      p={4}
      w="100%"
      {...props}
    >
      {children}
    </Flex>
  );
};
