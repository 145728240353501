import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, Flex, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';

import { Routes } from '../../enums/routes';
import { LogoSmall } from '../../images/LogoSmall';
import { PageContainer } from '../generic/PageContainer';

/** Default navigation */
export const Nav = ({ title, RightButton }: { title?: string; RightButton?: ButtonProps }) => {
  return (
    <Flex backgroundColor="gray.50" width="100%">
      <Flex
        alignItems="center"
        justifyContent="center"
        maxWidth={['100%', '100%', '768px', '768px']}
        mx="auto"
        pb={4}
        position="relative"
        pt={6}
        width="full"
      >
        <NextLink href={Routes.HOME}>
          <Link cursor="pointer" left={0} my="auto" position="absolute" px={4} py={2}>
            <LogoSmall />
          </Link>
        </NextLink>
        {title && <Text fontWeight="extrabold">{title} </Text>}
        {RightButton && (
          <Button my="auto" position="absolute" right={4} size="sm" {...RightButton} />
        )}
      </Flex>
    </Flex>
  );
};

export const SubNavigation = ({ rightContent }: { rightContent?: ReactNode }) => {
  const router = useRouter();

  const back = useCallback(() => router.back(), []);

  return (
    <Flex backgroundColor="synchrony.teal" fontWeight="extrabold" px={4} py={2} width="100%">
      <PageContainer py={0}>
        <Flex align={'center'} justify={'space-between'}>
          <Link onClick={back} textColor={'white'} variant="outlined">
            <ChevronLeftIcon />
            Back
          </Link>
          {rightContent}
        </Flex>
      </PageContainer>
    </Flex>
  );
};
