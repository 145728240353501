import { Icon, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';

import { Eye } from '../icons/Eye';
import { EyeHidden } from '../icons/EyeHidden';

/**
 * Input component that renders a password input field.
 */
export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [show, setShow] = useState(false);

  return (
    <InputGroup>
      <Input {...props} ref={ref} type={show ? 'text' : 'password'} />
      <InputRightElement cursor="pointer" onClick={() => setShow(!show)}>
        <Icon as={show ? Eye : EyeHidden} />
      </InputRightElement>
    </InputGroup>
  );
});
