import { InputProps, Select } from '@chakra-ui/react';
import { RegisterOptions } from 'react-hook-form';

import { PasswordInput } from '../components/form/PasswordInput';
import { StateOptions } from '../components/form/StateOptions';
import { DOB_REGEX, EMAIL_REGEX, PHONE_REGEX, ZIPCODE } from '../constants';
import { formatAsYouTypeDOB, formatAsYouTypePhoneNumber } from '../utils/formatting';
import { isDOBValid } from '../utils/validation';

export enum InputTypes {
  ADDRESS_ONE = 'addressOne',
  ADDRESS_TWO = 'addressTwo',
  CITY = 'city',
  ZIP = 'zip',
  STATE = 'state',
  EMAIL = 'email',
  PASSWORD = 'password',
  PHONE = 'phone',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DATE_OF_BIRTH = 'dateOfBirth',
}

/**
 * Defines default options based to the input for given input type
 */
export const inputOptions: {
  [key in InputTypes]: InputProps;
} = {
  addressOne: {
    placeholder: 'Street Address',
    type: 'text',
  },
  addressTwo: {
    placeholder: 'Apt #',
    type: 'text',
  },
  zip: {
    placeholder: '00000',
    type: 'text',
    maxLength: 5,
    inputMode: 'numeric',
  },
  city: {
    type: 'text',
    placeholder: 'City',
  },
  state: {
    as: Select,
    placeholder: 'Please select a state',
    children: <StateOptions />,
  },
  email: {
    autoCapitalize: 'none',
    inputMode: 'email',
    placeholder: 'james@email.com',
    type: 'text',
  },
  password: {
    as: PasswordInput,
    placeholder: 'password',
  },
  phone: {
    placeholder: '000-000-0000',
    inputMode: 'numeric',
    maxLength: 12,
    type: 'tel',
  },
  firstName: {
    placeholder: 'First Name',
    type: 'text',
  },
  lastName: {
    placeholder: 'Last Name',
    type: 'text',
  },
  dateOfBirth: {
    inputMode: 'numeric',
    maxLength: 10,
    placeholder: 'MM/DD/YYYY',
    type: 'text',
  },
};

/**
 * Defines default rules based to react hook form for given input type
 */
export const inputRules: {
  [key in InputTypes]: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
} = {
  addressOne: {
    required: 'Street address is required',
  },
  addressTwo: {},
  zip: {
    required: 'ZIP Code is required',
    pattern: {
      value: ZIPCODE,
      message: 'Invalid ZIP code',
    },
  },
  city: {
    required: 'City is required',
  },
  state: {
    required: 'State is required',
  },
  email: {
    required: 'Email is required',
    pattern: {
      value: EMAIL_REGEX,
      message: 'Invalid email',
    },
  },
  password: {
    required: 'Password is required',
  },
  phone: {
    required: 'Phone is required',
    pattern: {
      value: PHONE_REGEX,
      message: 'Phone must be in the format: ###-###-####',
    },
  },
  firstName: {
    required: 'First name is required',
  },
  lastName: {
    required: 'Last name is required',
  },
  dateOfBirth: {
    required: 'Date of birth is required',
    maxLength: 10,
    pattern: {
      value: DOB_REGEX,
      message: 'Use format "MM/DD/YYYY"',
    },
    validate: (date) => {
      if (!isDOBValid(date)) return 'Invalid Date';

      return true;
    },
  },
};

/**
 * Defines default label for given input type
 */
export const inputLabels: {
  [key in InputTypes]: string;
} = {
  addressOne: 'Street Address',
  addressTwo: 'Apt / Suite / Other',
  zip: 'ZIP Code',
  city: 'City',
  state: 'State',
  email: 'Email Address',
  password: 'Password',
  phone: 'Phone Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
};

/**
 * Defines formatting function for given input type
 */
export const inputFormatting: {
  [key in InputTypes]?: (value: string) => string;
} = {
  phone: (value) => formatAsYouTypePhoneNumber(value) || '',
  dateOfBirth: (value) => formatAsYouTypeDOB(value) || '',
};
