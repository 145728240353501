import { STATE_OPTIONS } from '../../constants';
import { formatStateName } from '../../utils/formatting';

/** Create options for all the states */
export const StateOptions = () => {
  return (
    <>
      {STATE_OPTIONS.map((state) => (
        <option key={state.value} value={state.value}>
          {formatStateName(state.name)}
        </option>
      ))}
    </>
  );
};
