import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';

import { isPhoneE164, isPhoneValid } from './validation';

// View FORMATTING.md for more information

interface NameProp {
  id: string;
  firstName: string;
  lastName: string;
}

/**
 * Format the name
 * Optionally pass the customer ID of logged in user if you want (self) next to their own name
 */
export const formatName = (customer: NameProp, selfId?: string) => {
  return `${customer.firstName} ${customer.lastName}${selfId === customer.id ? ' (self)' : ''}`;
};

/* --------------- */
/* DATE FORMATTING */
/* --------------- */

export enum DateFormats {
  USER_DISPLAY = 'MM/DD/YYYY',
  FULL_USER_DISPLAY = 'MMM D YYYY',
  API_DATE = 'YYYY-MM-DD',
}

/** Make everything UTC for standardization */
export const utcDate = (date: string, format?: string) => moment.utc(date, format);

/**
 * "As you type" Formats digits to ##/##/####
 */
export const formatAsYouTypeDOB = (
  dateOfBirth: string | undefined | null
): string | null | undefined => {
  if (!dateOfBirth) return null;

  const date = dateOfBirth.replace(/[^\d]/g, '');

  const length = date.length;

  if (length < 3) return date;
  else if (length < 5) {
    return `${date.slice(0, 2)}/${date.slice(2)}`;
  } else {
    return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4)}`;
  }
};

/**
 * "As you type" Formats digits to ##/##/####
 */
export const formatEmail = (email?: string | null): string | undefined => {
  return email?.trim().toLowerCase();
};

/* --------------- */
/* PHONE FORMATTING */
/* --------------- */

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * "As you type" Formats digits to ###-###-####
 */
export const formatAsYouTypePhoneNumber = (
  phoneNumber: string | undefined | null
): string | null | undefined => {
  if (!phoneNumber) return null;

  const phone = phoneNumber.replace(/[^\d]/g, '');

  const length = phone.length;

  if (length < 4) return phone;
  else if (length < 7) {
    return `${phone.slice(0, 3)}-${phone.slice(3)}`;
  } else {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  }
};

/**
 * Example input: 123-456-7890
 * Example output: +11234567890
 */
export const formatDisplayPhoneToE164 = (phone: string): string => {
  return `+1${cleanPhoneNumber(phone)}`;
};

/**
 * Example input: 1234567890
 * Example output: +11234567890
 */
export const formatKeycentrixPhoneToE164 = (phoneNumber: string): string | null => {
  if (isPhoneE164(phoneNumber)) return phoneNumber;
  if (!isPhoneValid(phoneNumber)) return null;

  const phone = phoneNumber.replace(/[^\d]/g, '');

  const phoneRaw = phoneUtil.parseAndKeepRawInput(phone, 'US');

  if (!phoneUtil.isPossibleNumber(phoneRaw)) return null;

  return phoneUtil.format(phoneRaw, PhoneNumberFormat.E164);
};

/**
 * Should never be passed a number that is not in E164.
 */
export const formatE164ForDisplay = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return '';

  if (isPhoneE164(phoneNumber)) {
    return formatAsYouTypePhoneNumber(phoneNumber.slice(2));
  }

  throw new Error('Invalid phone number format');
};

export const cleanPhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/\D/g, '');

/* --------------- */
/* ADDRESS FORMATTING */
/* --------------- */

export const formatAddressLineOne = (
  address?: {
    addressOne: string;
    addressTwo?: string | null;
  } | null
) => {
  if (!address) {
    return '';
  }

  return `${address.addressOne}${address.addressTwo ? `, ${address.addressTwo}` : ''}`;
};

export const formatAddressLineTwo = (
  address?: { city: string; state: string; zip: string } | null
) => {
  if (!address) {
    return '';
  }

  return `${address.city}, ${address.state} ${address.zip}`;
};

export const formatStateName = (state: string) => {
  return state
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
