import React from 'react';
import { FormControl, FormLabel, Input, InputGroup, InputProps } from '@chakra-ui/react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
  UseFormSetValue,
} from 'react-hook-form';

import {
  inputFormatting,
  inputLabels,
  inputOptions,
  inputRules,
  InputTypes,
} from '../../options/inputOptions';

import { ErrorText } from './ErrorText';

export interface FormInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  inputProps?: InputProps;
  label?: string;
  hideLabel?: boolean;
  AdditionalInfo?: React.ReactNode;
  setValue?: UseFormSetValue<TFieldValues>;
  RightElement?: React.ReactNode;
}

/**
  Takes advantage of react hook form's [useController](https://react-hook-form.com/api/usecontroller) hook
  to register its own values. This means that it will register itself without you having to execute the
  `register` function.

  Additionally, this component uses the options defined in [inputOptions.tsx](/options/inputOptions.tsx)
  by default for an input with the given `InputType` as the name.  This allows setting default input
  options, form rules, formatting, and labels for a given input type and then overriding any of them as
  needed by passing props when rendering.
 */
export const FormInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: FormInputProps<TFieldValues, TName>
) => {
  const { inputProps, controllerProps, AdditionalInfo, RightElement, hideLabel, setValue } = props;
  const id = getId(controllerProps.name);
  const defaultRules = inputRules[id] || {};
  const defaultInputOptions = inputOptions[id] || {};
  const label = props.label || inputLabels[id];

  const control = useController<TFieldValues, TName>({
    ...controllerProps,
    rules: {
      ...defaultRules,
      ...controllerProps.rules,
    },
  });

  const { error } = control?.fieldState;

  /** Override onChange if a custom formatting function is defined */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const format = inputFormatting[id];

    if (format) {
      if (!setValue) {
        throw new Error('FormInput: setValue is required if you want to format the input');
      }

      setValue(controllerProps.name, format(e.target.value) as any, { shouldDirty: true });
    } else {
      control.field.onChange(e);
    }
  };

  return (
    <FormControl id={id}>
      {!hideLabel && label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <InputGroup>
        <Input
          data-cy={`${controllerProps.name}-input`}
          isInvalid={!!error}
          mr={RightElement ? 2 : 0}
          {...control.field}
          onChange={onChange}
          {...defaultInputOptions}
          {...inputProps}
        />
        {RightElement}
      </InputGroup>
      {error && <ErrorText>{error.message}</ErrorText>}
      {AdditionalInfo}
    </FormControl>
  );
};

/**
 * Get the ID as the last value in the key in case it is multi-part
 * @example 'primaryAddress.addressOne' => 'addressOne'
 * @example 'addressOne' => 'addressOne'
 */
const getId = (name: string): InputTypes => {
  const splitId = name.split('.');

  return splitId[splitId.length - 1] as InputTypes;
};
