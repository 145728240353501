import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';

import { PHONE_US_E164_REGEX } from '../constants';

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * Checks if phone number is real using isPossible.
 */
export const isPhoneValid = (phoneNumber: string): boolean => {
  const phone = phoneNumber.replace(/[^\d]/g, '');

  if (!phone) return false;

  const phoneRaw = phoneUtil.parseAndKeepRawInput(phone, 'US');

  if (!phoneUtil.isPossibleNumber(phoneRaw)) return false;

  return true;
};

export const isPhoneE164 = (phoneNumber: string) => PHONE_US_E164_REGEX.test(phoneNumber);

/**
 * Checks DOB in MM/DD/YY or MM-DD-YY format for validity.
 */
export const isDOBValid = (date: string): boolean => {
  const dateDigits = date.toString().replace(/[^\d]/g, '');

  if (!dateDigits) return false;

  const d = moment(new Date(date));

  // Invalid date.
  if (!d.isValid()) return false;

  // DOB cannot be in the future.
  if (d.isAfter(moment())) return false;

  return true;
};

/**
 * Checks valid DOB and returns true if age is >= 18
 */
export const isEighteenOrOver = (date: string): boolean => {
  if (!date) return false;

  const now = moment();
  const d = moment(new Date(date));

  // under 18
  if (now.diff(d, 'years') < 18) {
    return false;
  }

  // over 18
  return true;
};
